// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { MuiColorInput } from 'mui-color-input'
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import React, { useState, useEffect } from "react";
import { wsScannedCardList } from "actions/webserviceActions";
import { connect } from 'react-redux'
import { PropTypes } from "prop-types";
import { fetchIsFulfilled } from "utils/fetchStateSelector";
import MDButton from "components/MDButton";
import { Button, Icon, TextField, Checkbox, FormGroup, FormControlLabel } from "@mui/material";
import { FormControl } from '@mui/base/FormControl';
import { wsScannedCardDelete } from "actions/webserviceActions";
import { ModalContent, Modal, StyledBackdrop } from "components/Modal";
import { wsScannedCardDeleteMemRelease } from "actions/webserviceActions";
import { wsScannedCardSaveMemRelease } from "actions/webserviceActions";
import linearGradient from "assets/theme/functions/linearGradient";
import defaultProfile from 'assets/images/default-profile.svg'
import shareIcon from 'assets/images/share.svg'
import BusinessCard from "components/BusinessCard";

function ScannedCards({ dispatch, scannedCardList, scannedCardDelete }) {
  const [nameError, setNameError] = useState("");
  const [rows, setRows] = useState([])
  const [open, setOpen] = useState(false);
  const [projectData, setProjectData] = useState({ name: "" })
  const bgColor = linearGradient("#EE5B5B", "#CD2F2F", 135)
  const handleOpen = () => { setNameError(""); setOpen(true) };
  const handleClose = () => { setNameError(""); setOpen(false) };
  const columns = [{ Header: "Wizytówka", accessor: "preview", width: "45%", align: "left" },
  { Header: "Akcja", accessor: "actions", align: "left" }]

  const onEdit = (item) => {
    setProjectData({ ...item })
    handleOpen()
  }

  const onDelete = (item) => {
    dispatch(wsScannedCardDelete({ id: "global" }, item.id))
  }

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setProjectData(values => ({ ...values, [name]: value }))
  }

  useEffect(() => {
    dispatch(wsScannedCardList({ id: "global" }))
  }, []);

  useEffect(() => {
    if (fetchIsFulfilled(scannedCardList)) {
      let rows = []
      if (scannedCardList.data != null && scannedCardList.data.length) {
        scannedCardList.data.forEach(card => {
          rows.push(
            {
              ...card,
              preview: <BusinessCard card={card} checkbox={false} />,
              actions: (<>{/* <Button onClick={() => onEdit(card)}>Edycja</Button> */}<Button onClick={() => onDelete(card)}>Usuń</Button></>)
            })
        })
      }
      setRows(rows)
    }
  }, [scannedCardList.data]);

  useEffect(() => {
    if (fetchIsFulfilled(scannedCardDelete)) {
      dispatch(wsScannedCardList({ id: "global" }))
      dispatch(wsScannedCardDeleteMemRelease({ id: "global" }))
    }
  }, [scannedCardDelete.data]);

  const onSubmit = (e) => {
    e.preventDefault();
    if (projectData.name == null || projectData.name === "") {
      setNameError("Nazwa projektu nie może być pusta")
    } else {
      //dispatch(wsScannedCardSave({ id: "global" }, { ...projectData, card_data_mask: mask}))
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="primary"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <MDTypography variant="h6" color="white">
                  Zeskanowane wizytówki
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows: rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

ScannedCards.propTypes = {
  dispatch: PropTypes.func,
  scannedCardList: PropTypes.object,
  scannedCardDelete: PropTypes.object
}
export default connect(store => {
  let scannedCardList = (store.webservice.scannedCardList != null && store.webservice.scannedCardList.length) ? store.webservice.scannedCardList[0] : []
  //let scannedCardSave = (store.webservice.scannedCardSave != null && store.webservice.scannedCardSave.length) ? store.webservice.scannedCardSave[0] : []
  let scannedCardDelete = (store.webservice.scannedCardDelete != null && store.webservice.scannedCardDelete.length) ? store.webservice.scannedCardDelete[0] : []
  return { scannedCardList, scannedCardDelete }
})(ScannedCards);