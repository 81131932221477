// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { MuiColorInput } from 'mui-color-input'
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import React, { useState, useEffect } from "react";
import { wsCardProjectList } from "actions/webserviceActions";
import { connect } from 'react-redux'
import { PropTypes } from "prop-types";
import { fetchIsFulfilled } from "utils/fetchStateSelector";
import MDButton from "components/MDButton";
import { Button, Icon, TextField, Checkbox, FormGroup, FormControlLabel } from "@mui/material";
import { FormControl } from '@mui/base/FormControl';
import { wsCardProjectSave } from "actions/webserviceActions";
import { wsCardProjectDelete } from "actions/webserviceActions";
import { ModalContent, Modal, StyledBackdrop } from "components/Modal";
import { wsCardProjectDeleteMemRelease } from "actions/webserviceActions";
import { wsCardProjectSaveMemRelease } from "actions/webserviceActions";
import MDInput from "components/MDInput";

function CardProjects({ dispatch, cardProjectList, cardProjectSave, cardProjectDelete }) {
  const [nameError, setNameError] = useState("");
  const [groups, setGroups] = useState([])
  const [open, setOpen] = useState(false);
  const [bgFrom, setBgFrom] = React.useState("#EE5B5B")
  const [bgTo, setBgTo] = React.useState('#CD2F2F')
  const [projectData, setProjectData] = useState({ name: "" })
  const [mask, setMask] = useState(255)
  const handleOpen = (edit) => { 
    setNameError(""); 
    if(!edit){
      setBgFrom("#EE5B5B");
      setBgTo("#CD2F2F");
      setProjectData({ name: "" }); 
      setMask(255);
    }
    setOpen(true) 
  };
  const handleClose = () => { setNameError(""); setOpen(false) };
  const columns = [{ Header: "Nazwa", accessor: "name", width: "45%", align: "left" },
  { Header: "Akcja", accessor: "actions", align: "left" }]

  const onEdit = (item) => {
    setProjectData({ ...item })
    if(item.bg_color_from != null){
      setBgFrom(item.bg_color_from)
    } else {
      setBgFrom("#EE5B5B")
    }
    if(item.bg_color_to != null){
      setBgTo(item.bg_color_to)
    } else {
      setBgTo("#CD2F2F")
    }
    
    setMask(item.card_data_mask)
    handleOpen(true)
  }

  const onDelete = (item) => {
    dispatch(wsCardProjectDelete({ id: "global" }, item.id))
  }

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setProjectData(values => ({ ...values, [name]: value }))
  }

  const handleMaskChange = (event, value) => {
    if(event.target.checked){
      setMask(mask+value)
    } else {
      setMask(mask-value)
    }
  }

  const handleBGFromChange = (e) => {
    setBgFrom(e.target.value)
  }

  const handleBGToChange = (e) => {
    setBgTo(e.target.value)
  }

  useEffect(() => {
    dispatch(wsCardProjectList({ id: "global" }))
  }, []);

  useEffect(() => {
    if (fetchIsFulfilled(cardProjectList)) {
      let rows = []
      if (cardProjectList.data != null && cardProjectList.data.length) {
        cardProjectList.data.forEach(group => {
          rows.push({ ...group, actions: (<><Button onClick={() => onEdit(group)}>Edycja</Button><Button onClick={() => onDelete(group)}>Usuń</Button></>) })
        })
      }
      setGroups(rows)
    }
  }, [cardProjectList.data]);

  useEffect(() => {
    if (fetchIsFulfilled(cardProjectSave)) {
      dispatch(wsCardProjectList({ id: "global" }))
      dispatch(wsCardProjectSaveMemRelease({ id: "global" }))
      handleClose()
    }
  }, [cardProjectSave.data]);

  useEffect(() => {
    if (fetchIsFulfilled(cardProjectDelete)) {
      dispatch(wsCardProjectList({ id: "global" }))
      dispatch(wsCardProjectDeleteMemRelease({ id: "global" }))
    }
  }, [cardProjectDelete.data]);

  const onSubmit = (e) => {
    e.preventDefault();
    if (projectData.name == null || projectData.name === "") {
      setNameError("Nazwa projektu nie może być pusta")
    } else {
      dispatch(wsCardProjectSave({ id: "global" }, { ...projectData, card_data_mask: mask, bg_color_from: bgFrom, bg_color_to: bgTo }))
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="primary"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <MDTypography variant="h6" color="white">
                  Projekty wizytówek
                </MDTypography>
                <MDButton variant="gradient" color="dark" onClick={() => { handleOpen(false) }}>
                  <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                  &nbsp;dodaj
                </MDButton>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows: groups }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
                <Modal
                  aria-labelledby="unstyled-modal-title"
                  aria-describedby="unstyled-modal-description"
                  open={open}
                  onClose={handleClose}
                  slots={{ backdrop: StyledBackdrop }}
                >
                  <ModalContent sx={{ width: 400 }}>
                    <h2 id="unstyled-modal-title" className="modal-title" style={{ marginBottom: 20 }}>
                      {projectData.id != null ? "Edytuj" : "Dodaj"} projekt
                    </h2>
                    <FormControl defaultValue="" required >
                      <TextField
                        id="outlined-basic"
                        fullWidth
                        required
                        label="Nazwa projektu"
                        error={nameError}
                        helperText={nameError}
                        name="name"
                        value={projectData.name}
                        variant="outlined"
                        onChange={handleChange}
                      />
                      <div style={{ height: 20 }}></div>
                      <h5>Gradient tła wizytówki</h5>
                      <div style={{ height: 20 }}></div>
                      <div style={{display: "flex"}}>
                        <MDInput type="color" fullWidth label="Kolor od" value={bgFrom} onChange={handleBGFromChange} />
                        <MDInput type="color" fullWidth style={{marginLeft: 10}} label="Kolor do" value={bgTo} onChange={handleBGToChange} />
                      </div>
                      <div style={{ height: 20 }}></div>
                      <h5>Wyświetlaj na wizytówce</h5>
                      <FormGroup>
                        <FormControlLabel control={<Checkbox defaultChecked checked={(mask & 1)}  onChange={e => handleMaskChange(e, 1)} name="mask" />} label="Zdjęcie" />
                        <FormControlLabel control={<Checkbox defaultChecked checked={(mask & 2)} color="primary"  onChange={e => handleMaskChange(e, 2)} name="mask" />} label="Imię" />
                        <FormControlLabel control={<Checkbox defaultChecked checked={(mask & 4)}  onChange={e => handleMaskChange(e, 4)} name="mask" />} label="Stanowisko" />
                        <FormControlLabel control={<Checkbox defaultChecked checked={(mask & 8)} sx={{
    color: "#ff0000" }} onChange={e => handleMaskChange(e, 8)} name="mask" />} label="Telefon" />
                        <FormControlLabel control={<Checkbox defaultChecked checked={(mask & 16)} onChange={e => handleMaskChange(e, 16)} name="mask" />} label="Email" />
                        <FormControlLabel control={<Checkbox defaultChecked checked={(mask & 32)} onChange={e => handleMaskChange(e, 32)} name="mask" />} label="Firma" />
                        <FormControlLabel control={<Checkbox defaultChecked checked={(mask & 64)} onChange={e => handleMaskChange(e, 64)} name="mask" />} label="Adres" />
                        <FormControlLabel control={<Checkbox defaultChecked checked={(mask & 128)} onChange={e => handleMaskChange(e, 128)} name="mask" />} label="URL" />
                      </FormGroup>
                      <div style={{ height: 20 }}></div>
                      <MDButton variant="gradient" color="dark" onClick={onSubmit} >
                        <Icon sx={{ fontWeight: "bold" }}>save</Icon>
                        &nbsp;zapisz
                      </MDButton>
                    </FormControl>
                  </ModalContent>
                </Modal>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

CardProjects.propTypes = {
  dispatch: PropTypes.func,
  cardProjectList: PropTypes.object,
  cardProjectSave: PropTypes.object,
  cardProjectDelete: PropTypes.object
}
export default connect(store => {
  let cardProjectList = (store.webservice.cardProjectList != null && store.webservice.cardProjectList.length) ? store.webservice.cardProjectList[0] : []
  let cardProjectSave = (store.webservice.cardProjectSave != null && store.webservice.cardProjectSave.length) ? store.webservice.cardProjectSave[0] : []
  let cardProjectDelete = (store.webservice.cardProjectDelete != null && store.webservice.cardProjectDelete.length) ? store.webservice.cardProjectDelete[0] : []
  return { cardProjectList, cardProjectSave, cardProjectDelete }
})(CardProjects);