import { createInjectSagasStore, sagaMiddleware } from 'redux-sagas-injector';
import { applyMiddleware, combineReducers, compose } from "redux"

import sagas from './sagas'
import reducers from "./reducers"

import exposeReactToGlobalScope from './utils/exposeReactToGlobalScope'
//import { initExternalAppLoader } from './externalAppLoader'

const enhancers = [
  applyMiddleware(
    sagaMiddleware
  )];

const store = createInjectSagasStore({ 'rootSaga': sagas }, combineReducers(reducers()), undefined, compose(...enhancers));

exposeReactToGlobalScope(store)
//initExternalAppLoader(store)

export default store