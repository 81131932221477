// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";
// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-sign-up-cover.jpeg";
import { apiUrl } from "helpers/const";
import { useState } from "react";
import {toast} from 'react-toastify'

function Cover() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const navigate = useNavigate();
  const submitHandler = (e) => {
    let fetchAddress = apiUrl;
    fetch(fetchAddress + "account/register", {
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({name, email, password}),
      credentials: "include"
    })
      .then(async response => {
        if (!response.ok) {
          const text = await response.text();
          toast.error(JSON.parse(text).error)
          return;
        }
        toast.success("Rejestracja zakończona. Możesz się teraz zalogować na swoje konto.");
        setTimeout(() => {
          navigate('/authentication/sign-in')
        }, 5000)
      })
      .catch(response => {
        if (response.status != null) {
          switch (response.status) {
            case 500: response.errorMessage = response.statusText + " (" + response.status + ")"; break;
            default: response.errorMessage = JSON.parse(response.text).error; break;
          }
        }
        if (response.message != null) response.errorMessage = "Brak połączenia z serwerem"
        toast.error(response.errorMessage);
        return;
      })
    e.preventDefault();
  }
  return (
    <CoverLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="primary"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Dołącz do nas
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Wprowadź email oraz hasło, aby się zarejestrować.
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput type="text" label="Imię" variant="standard" fullWidth value={name} onChange={e => setName(e.target.value)} />
            </MDBox>
            <MDBox mb={2}>
              <MDInput type="email" label="Email" variant="standard" fullWidth value={email} onChange={e => setEmail(e.target.value)} />
            </MDBox>
            <MDBox mb={2}>
              <MDInput type="password" label="Hasło" variant="standard" fullWidth value={password} onChange={e => setPassword(e.target.value)}  />
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Checkbox />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Akceptuję&nbsp;
              </MDTypography>
              <MDTypography
                component="a"
                href="#"
                variant="button"
                fontWeight="bold"
                color="primary"
                textGradient
              >
                Regulamin oraz Politykę prywatności
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="primary" fullWidth onClick={submitHandler}>
                zarejestruj się
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Masz już konto?{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/sign-in"
                  variant="button"
                  color="primary"
                  fontWeight="medium"
                  textGradient
                >
                  Zaloguj się
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default Cover;
