// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import React, { useState, useEffect } from "react";
import { wsCardGroupList } from "actions/webserviceActions";
import { connect } from 'react-redux'
import { PropTypes } from "prop-types";
import { fetchIsFulfilled } from "utils/fetchStateSelector";
import MDButton from "components/MDButton";
import { Button, Icon, TextField } from "@mui/material";
import { FormControl } from '@mui/base/FormControl';
import { wsCardGroupSave, wsScannedCardList } from "actions/webserviceActions";
import { wsCardGroupDelete } from "actions/webserviceActions";
import { ModalContent, Modal, StyledBackdrop } from "components/Modal";
import { wsCardGroupDeleteMemRelease } from "actions/webserviceActions";
import { wsCardGroupSaveMemRelease } from "actions/webserviceActions";
import BusinessCard from "components/BusinessCard";
import { wsScannedCardGroupAssignment } from "actions/webserviceActions";

function CardGroups({ dispatch, cardGroupList, cardGroupSave, cardGroupDelete, scannedCardList }) {
  const [nameError, setNameError] = useState("");
  const [groups, setGroups] = useState([])
  const [scannedCards, setScannedCards] = useState([])
  const [open, setOpen] = useState(false);
  const [groupData, setGroupData] = useState({ name: "" })
  const [assignedToGroup, setAssignedToGroup] = useState([])
  const handleOpen = () => { setNameError(""); setOpen(true) };
  const handleClose = () => { setNameError(""); setOpen(false) };
  const [refresh, setRefresh] = useState(false)
  const columns = [{ Header: "Nazwa", accessor: "name", width: "45%", align: "left" }, { Header: "Przypisano", accessor: "assigned", width: "45%", align: "left" },
  { Header: "Akcja", accessor: "actions", align: "left" }]

  const onEdit = (item) => {
    setGroupData({ ...item })
    initAssignedToGroup(item)
    handleOpen()
  }

  const onAdd = () => {
    setGroupData({name: ""})
    initAssignedToGroup({})
    handleOpen()
  }

  const onDelete = (item) => {
    dispatch(wsCardGroupDelete({ id: "global" }, item.id))
  }

  const onCheck = (e) => {
    if(e != null){
      let newAssignedToGroup = assignedToGroup
      if(newAssignedToGroup.includes(e.target.value)){
        newAssignedToGroup = assignedToGroup.filter(item => item !== e.target.value)        
      } else {
        newAssignedToGroup.push(e.target.value)
      }
      setAssignedToGroup(newAssignedToGroup)
      setRefresh(!refresh)
    }
  }

  const initAssignedToGroup = (groupDataProp) => {
    if(groupDataProp.id != null){
      let newAssignedToGroup = groupDataProp.cards.map(card => card.id)
      setAssignedToGroup(newAssignedToGroup)
    } else {
      setAssignedToGroup([])
    }
  }

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setGroupData(values => ({ ...values, [name]: value }))
  }

  useEffect(() => {
    dispatch(wsCardGroupList({ id: "global" }))
    dispatch(wsScannedCardList({ id: "global" }))
  }, []);

  useEffect(() => {
    if (fetchIsFulfilled(cardGroupList)) {
      let rows = []
      if (cardGroupList.data != null && cardGroupList.data.length) {
        cardGroupList.data.forEach(group => {
          rows.push({ ...group, assigned: group.cards.length, actions: (<><Button onClick={() => onEdit(group)}>Edycja</Button><Button onClick={() => onDelete(group)}>Usuń</Button></>) })
        })
      }
      setGroups(rows)
    }
  }, [cardGroupList.data]);

  useEffect(() => {
    if (fetchIsFulfilled(scannedCardList)) {
      let rows = []
      if (scannedCardList.data != null && scannedCardList.data.length) {
        scannedCardList.data.forEach(scannedCard => {
          rows.push({ ...scannedCard })
        })
      }
      setScannedCards(rows)
    }
  }, [scannedCardList.data]);

  useEffect(() => {
    if (fetchIsFulfilled(cardGroupSave)) {
      dispatch(wsScannedCardGroupAssignment({id: "global"}, cardGroupSave.data, assignedToGroup))
      dispatch(wsCardGroupList({ id: "global" }))
      dispatch(wsCardGroupSaveMemRelease({ id: "global" }))
      handleClose()
    }
  }, [cardGroupSave.data]);

  useEffect(() => {
    if (fetchIsFulfilled(cardGroupDelete)) {
      dispatch(wsCardGroupList({ id: "global" }))
      dispatch(wsCardGroupDeleteMemRelease({ id: "global" }))
    }
  }, [cardGroupDelete.data]);

  const onSubmit = (e) => {
    e.preventDefault();
    if (groupData.name == null || groupData.name === "") {
      setNameError("Nazwa grupy nie może być pusta")
    } else {
      dispatch(wsCardGroupSave({ id: "global" }, { ...groupData }))
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="primary"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <MDTypography variant="h6" color="white">
                  Grupy wizytówek
                </MDTypography>
                <MDButton variant="gradient" color="dark" onClick={() => { onAdd() }}>
                  <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                  &nbsp;dodaj
                </MDButton>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows: groups }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
                <Modal
                  aria-labelledby="unstyled-modal-title"
                  aria-describedby="unstyled-modal-description"
                  open={open}
                  onClose={handleClose}
                  slots={{ backdrop: StyledBackdrop }}
                >
                  <ModalContent sx={{ width: 400 }}>
                    <h2 id="unstyled-modal-title" className="modal-title" style={{ marginBottom: 20 }}>
                      {groupData.id != null ? "Edytuj" : "Dodaj"} grupę wizytówek
                    </h2>
                    <FormControl defaultValue="" required >
                      <TextField
                        id="outlined-basic"
                        fullWidth
                        required
                        label="Nazwa grupy wizytówek"
                        error={nameError}
                        helperText={nameError}
                        name="name"
                        value={groupData.name}
                        variant="outlined"
                        onChange={handleChange}
                      />
                      <div style={{ height: 20 }}></div>
                      <MDTypography variant="h5" color="black">
                        Dodaj wizytówki
                      </MDTypography>
                      <MDTypography variant="h6" color="black">{assignedToGroup.length} wizytówek</MDTypography>
                      {scannedCards.map(card => <BusinessCard key={card.id} card={card} checked={assignedToGroup.includes(card.id)} checkbox={true} onCheck={onCheck} />)}
                      <MDButton variant="gradient" color="dark" onClick={onSubmit} >
                        <Icon sx={{ fontWeight: "bold" }}>save</Icon>
                        &nbsp;zapisz
                      </MDButton>
                    </FormControl>
                  </ModalContent>
                </Modal>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

CardGroups.propTypes = {
  dispatch: PropTypes.func,
  cardGroupList: PropTypes.object,
  cardGroupSave: PropTypes.object,
  cardGroupDelete: PropTypes.object,
  scannedCardList: PropTypes.object
}
export default connect(store => {
  let cardGroupList = (store.webservice.cardGroupList != null && store.webservice.cardGroupList.length) ? store.webservice.cardGroupList[0] : []
  let scannedCardList = (store.webservice.scannedCardList != null && store.webservice.scannedCardList.length) ? store.webservice.scannedCardList[0] : []
  let cardGroupSave = (store.webservice.cardGroupSave != null && store.webservice.cardGroupSave.length) ? store.webservice.cardGroupSave[0] : []
  let cardGroupDelete = (store.webservice.cardGroupDelete != null && store.webservice.cardGroupDelete.length) ? store.webservice.cardGroupDelete[0] : []
  return { cardGroupList, cardGroupSave, cardGroupDelete, scannedCardList }
})(CardGroups);