import MDBox from "components/MDBox";
import linearGradient from "assets/theme/functions/linearGradient";
import defaultProfile from 'assets/images/default-profile.svg'
import shareIcon from 'assets/images/share.svg'
import { PropTypes } from "prop-types";
import MDInput from "components/MDInput";
import { Checkbox, Icon } from "@mui/material";
import { Modal, ModalContent, StyledBackdrop } from "components/Modal";
import MDButton from "components/MDButton";
import { useState } from "react";

function BusinessCard({ card, checkbox, checked, onCheck }) {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false)
  }

  console.log("business", checked, checkbox)
  let bgColor = "grey"
  if (card != null) {
    if (card.card_project == null) {
      bgColor = linearGradient("#EE5B5B", "#CD2F2F", 135)
    } else {
      bgColor = linearGradient(card.card_project.bg_color_from, card.card_project.bg_color_to, 135)
    }
  }
  console.log("open", open)
  return <MDBox
    fontFamily='"Montserrat", sans-serif!important' fontOpticalSizing='auto' fontStyle="normal"
    display="flex" alignSelf="center" flexDirection="row" marginBottom="10px"
    sx={{
      width: {
        xs: 350, sm: 350, md: 350, lg: 350, xl: 350, xxl: 350
      },
      height: {
        xs: 124, sm: 124, md: 124, lg: 124, xl: 124, xxl: 124
      },
      padding: {
        xs: 2, sm: 2, md: 2, lg: 2, xl: 2, xxl: 2
      },
    }}
    bgColor={bgColor}
    border="0px solid rgba(0, 0, 0, 0.125)" borderRadius="0.75rem" boxShadow="rgba(0, 0, 0, 0.1) 0rem 0.25rem 0.375rem -0.0625rem, rgba(0, 0, 0, 0.06) 0rem 0.125rem 0.25rem -0.0625rem"
  >
    <MDBox
      component="img" borderRadius="50px" src={(card.photo_url != null && card.photo_url !== "") ? card.photo_url : defaultProfile} alt="Zdjęcie profilowe"
      sx={{
        width: {
          xs: 40, sm: 40, md: 40, lg: 40, xl: 40, xxl: 40
        },
        height: {
          xs: 40, sm: 40, md: 40, lg: 40, xl: 40, xxl: 40
        }
      }}
    />
    <MDBox marginLeft="10px" width="239px">
      <MDBox fontWeight="600" color="white" fontFamily='"Montserrat", sans-serif!important' lineHeight="20px"
        sx={{
          fontSize: {
            xs: 16, sm: 16, md: 16, lg: 16, xl: 16, xxl: 16
          }
        }}
      >
        {card != null && card.name}
      </MDBox>
      <MDBox color="white" fontWeight="500" fontFamily='"Montserrat", sans-serif!important' lineHeight="20px"
        sx={{
          fontSize: {
            xs: 14, sm: 14, md: 14, lg: 14, xl: 14, xxl: 14
          },
        }}
      >
        {card != null && card.job_position} w {card != null && card.company_name}
      </MDBox>
      <MDBox marginTop="9px" color="white" fontWeight="400" fontFamily='"Montserrat", sans-serif!important'
        sx={{
          fontSize: {
            xs: 14, sm: 14, md: 14, lg: 14, xl: 14, xxl: 14
          },
        }}
      >
        {card != null && card.phone}
      </MDBox>
      <MDBox color="white" fontWeight="400" fontFamily='"Montserrat", sans-serif!important'
        sx={{
          fontSize: {
            xs: 14, sm: 14, md: 14, lg: 14, xl: 14, xxl: 14
          },
        }}
      >
        {card != null && card.email}
      </MDBox>
    </MDBox>
    {checkbox ? <Checkbox checked={checked} onChange={onCheck} value={card.id} /> : <MDBox
      component="img" src={shareIcon} alt="Udostępnij" display="flex" alignSelf="center" marginLeft="5px" onClick={() => setOpen(true)}
      sx={{
        width: {
          xs: 24, sm: 24, md: 24, lg: 24, xl: 24, xxl: 24
        },
        height: {
          xs: 24, sm: 24, md: 24, lg: 24, xl: 24, xxl: 24
        },
        cursor: "pointer"
      }}
    />}
    <Modal
      aria-labelledby="unstyled-modal-title"
      aria-describedby="unstyled-modal-description"
      open={open}
      onClose={handleClose}
      slots={{ backdrop: StyledBackdrop }}
    >
      <ModalContent sx={{ width: 400 }}>
        <MDBox display="flex" alignSelf="center"
          component="img" borderRadius="40px" src={(card.photo_url != null && card.photo_url !== "") ? card.photo_url : defaultProfile} alt="Zdjęcie profilowe"
          sx={{
            width: {
              xs: 30, sm: 30, md: 30, lg: 30, xl: 30, xxl: 30
            },
            height: {
              xs: 30, sm: 30, md: 30, lg: 30, xl: 30, xxl: 30
            }
          }}
        />
        <MDBox display="flex" flexDirection="column">
          <MDBox fontWeight="700" fontFamily='"Montserrat", sans-serif!important' fontSize="14px" display="flex" justifyContent="center">{card.name}</MDBox>
          <MDBox fontWeight="700" fontFamily='"Montserrat", sans-serif!important' fontSize="12px" display="flex" justifyContent="center">{card.company_name}</MDBox>
        </MDBox>
        <MDBox display="flex" alignSelf="center" component="img" src={card.qr_code} alt="Kod QR" width="70%" marginBottom="10px" />

        <div style={{ height: 20 }}></div>
        <MDButton variant="gradient" color="dark" onClick={handleClose} >
          <Icon sx={{ fontWeight: "bold" }}>close</Icon>
          &nbsp;zamknij
        </MDButton>
      </ModalContent>
    </Modal>
  </MDBox>
}

BusinessCard.propTypes = {
  card: PropTypes.object,
  checked: PropTypes.boolean,
  checkbox: PropTypes.boolean,
  onCheck: PropTypes.function
}

export default BusinessCard;