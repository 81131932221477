/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "examples/Lists/ProfilesList";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";
import { PropTypes } from "prop-types";
// Overview page components
import Header from "layouts/profile/components/Header";
import PlatformSettings from "layouts/profile/components/PlatformSettings";

// Data
import profilesListData from "layouts/profile/data/profilesListData";

// Images
import homeDecor1 from "assets/images/home-decor-1.jpg";
import homeDecor2 from "assets/images/home-decor-2.jpg";
import homeDecor3 from "assets/images/home-decor-3.jpg";
import homeDecor4 from "assets/images/home-decor-4.jpeg";
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import { useState } from "react";
import { connect } from "react-redux";
import { wsAccountChangePassword } from "actions/webserviceActions";
import { Card } from "@mui/material";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { toast } from "react-toastify";

function Overview({userData, dispatch}) {
  const [tabValue, setTabValue] = useState(0);
  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");

  const submitHandler = (e) => {
    e.preventDefault();
    if (password !== password2) {
      toast.warning("Uwaga! Treści w polu 'Nowe hasło' oraz 'Powtórz hasło' nie są takie same!");
      return;
    }
    dispatch(wsAccountChangePassword({id: "global"}, oldPassword, password))
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header setTabValue={setTabValue} tabValue={tabValue} fullName={userData.name+" "+(userData.surname ?? '')}>
        {tabValue === 0 ? <MDBox mt={5} mb={3}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12} xl={12} sx={{ display: "flex" }}>
              <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
              <ProfileInfoCard
                title="Mój profil"
                description=""
                info={{
                  imięNazwisko: userData.name+" "+(userData.surname ?? ''),
                  telefon: userData.phone,
                  email: userData.email
                }}
                action={{ route: "", tooltip: "Edytuj profil" }}
                shadow={false}
              />
              <Divider orientation="vertical" sx={{ mx: 0 }} />
            </Grid>
          </Grid>
        </MDBox> : <MDBox pt={2} px={2} lineHeight={1.25}>
          <MDBox mb={1}>
            <Card>
              <MDBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="success"
                mx={2}
                mt={5}
                py={2}
                mb={1}
                textAlign="center"
              >
                <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
                  Ustaw nowe hasło
                </MDTypography>
                <MDTypography display="block" variant="button" color="white" my={1}>
                  Wprowadź dwukrotnie swoje nowe hasło, aby dokonać zmiany.
                </MDTypography>
              </MDBox>
              <MDBox pt={4} pb={3} px={3}>
                <MDBox component="form" role="form">
                  <MDBox mb={4}>
                  <MDInput type="password" label="Dotychczasowe hasło" variant="standard" fullWidth value={oldPassword}
                      onChange={(e) => {
                        setOldPassword(e.target.value);
                      }} />
                    <MDInput type="password" label="Nowe hasło" variant="standard" fullWidth value={password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }} />
                      <MDInput type="password" label="Powtórz hasło" variant="standard" fullWidth value={password2}
                      onChange={(e) => {
                        setPassword2(e.target.value);
                      }} />
                  </MDBox>
                  <MDBox mt={6} mb={1}>
                    <MDButton variant="gradient" color="info" fullWidth onClick={submitHandler}>
                      zmień
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </MDBox>
        </MDBox>}
      </Header>
      <Footer />
    </DashboardLayout>
  );
}

Overview.propTypes = {
  dispatch: PropTypes.func,
  userData: PropTypes.object
}

export default connect(store => {
  let cardProjectList = (store.webservice.cardProjectList != null && store.webservice.cardProjectList.length) ? store.webservice.cardProjectList[0] : []
  let userData = (store.webservice.userData != null && store.webservice.userData.data != null) ? store.webservice.userData.data : []
  return { cardProjectList, userData }
})(Overview);