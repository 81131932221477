import actions from './_actionTypes.js'
import { generateWebserviceFetchAction } from '../utils/actions.js'

export function wsUserLogIn(email, password) {
  return {
    type: actions.WS_USER_LOG_IN,
    payload: { email, password },
  }
}

export function wsUserLogInFulfilled(data) {
  return {
    type: actions.WS_USER_LOG_IN_FULFILLED,
    payload: { data }
  }
}

export function wsUserLogInRejected(error) {
  return {
    type: actions.WS_USER_LOG_IN_REJECTED,
    payload: { error }
  }
}

export function wsUserLogInErrorHide() {
	return {
		type: actions.WS_USER_LOG_IN_ERROR_HIDE,
		payload: {}
	}
}

export function wsUserLogOut() {
  return {
    type: actions.WS_USER_LOG_OUT,
  }
}

export function wsUserLogOutFulfilled(data) {
  return {
    type: actions.WS_USER_LOG_OUT_FULFILLED,
    payload: { data }
  }
}

export function wsUserLogOutRejected(error) {
  return {
    type: actions.WS_USER_LOG_OUT_REJECTED,
    payload: { error }
  }
}

export function wsUserLoggedIn(key) {
	return {
		type: actions.WS_USER_LOGGED_IN,
		payload: { key }
	}
}
export const wsUserLoggedInFulfilled = generateWebserviceFetchAction(actions.WS_USER_LOGGED_IN_FULFILLED)
export const wsUserLoggedInRejected = generateWebserviceFetchAction(actions.WS_USER_LOGGED_IN_REJECTED)
export const wsUserLoggedInErrorHide = generateWebserviceFetchAction(actions.WS_USER_LOGGED_IN_ERROR_HIDE)
export const wsUserLoggedInMemRelease = generateWebserviceFetchAction(actions.WS_USER_LOGGED_IN_MEM_RELEASE)
export const wsUserLoggedInMemRefInc = generateWebserviceFetchAction(actions.WS_USER_LOGGED_IN_MEM_REF_INC)
export const wsUserLoggedInMemRefDec = generateWebserviceFetchAction(actions.WS_USER_LOGGED_IN_MEM_REF_DEC)

export function wsUserRemindPassword(key, username, sendto) {
	return {
		type: actions.WS_USER_REMIND_PASSWORD,
		payload: { key, username, sendto }
	}
}
export const wsUserRemindPasswordFulfilled = generateWebserviceFetchAction(actions.WS_USER_REMIND_PASSWORD_FULFILLED)
export const wsUserRemindPasswordRejected = generateWebserviceFetchAction(actions.WS_USER_REMIND_PASSWORD_REJECTED)
export const wsUserRemindPasswordErrorHide = generateWebserviceFetchAction(actions.WS_USER_REMIND_PASSWORD_ERROR_HIDE)
export const wsUserRemindPasswordMemRelease = generateWebserviceFetchAction(actions.WS_USER_REMIND_PASSWORD_MEM_RELEASE)
export const wsUserRemindPasswordMemRefInc = generateWebserviceFetchAction(actions.WS_USER_REMIND_PASSWORD_MEM_REF_INC)
export const wsUserRemindPasswordMemRefDec = generateWebserviceFetchAction(actions.WS_USER_REMIND_PASSWORD_MEM_REF_DEC)

export function wsUserInfo(key) {
	return {
		type: actions.WS_USER_INFO,
		payload: { key }
	}
}
export const wsUserInfoFulfilled = generateWebserviceFetchAction(actions.WS_USER_INFO_FULFILLED)
export const wsUserInfoRejected = generateWebserviceFetchAction(actions.WS_USER_INFO_REJECTED)
export const wsUserInfoErrorHide = generateWebserviceFetchAction(actions.WS_USER_INFO_ERROR_HIDE)
export const wsUserInfoMemRelease = generateWebserviceFetchAction(actions.WS_USER_INFO_MEM_RELEASE)
export const wsUserInfoMemRefInc = generateWebserviceFetchAction(actions.WS_USER_INFO_MEM_REF_INC)
export const wsUserInfoMemRefDec = generateWebserviceFetchAction(actions.WS_USER_INFO_MEM_REF_DEC)

export function wsAccountList(key) {
	return {
		type: actions.WS_ACCOUNT_LIST,
		payload: { key }
	}
}
export const wsAccountListFulfilled = generateWebserviceFetchAction(actions.WS_ACCOUNT_LIST_FULFILLED)
export const wsAccountListRejected = generateWebserviceFetchAction(actions.WS_ACCOUNT_LIST_REJECTED)
export const wsAccountListErrorHide = generateWebserviceFetchAction(actions.WS_ACCOUNT_LIST_ERROR_HIDE)
export const wsAccountListMemRelease = generateWebserviceFetchAction(actions.WS_ACCOUNT_LIST_MEM_RELEASE)
export const wsAccountListMemRefInc = generateWebserviceFetchAction(actions.WS_ACCOUNT_LIST_MEM_REF_INC)
export const wsAccountListMemRefDec = generateWebserviceFetchAction(actions.WS_ACCOUNT_LIST_MEM_REF_DEC)

export function wsAccountChangePassword(key, oldPassword, newPassword) {
	return {
		type: actions.WS_ACCOUNT_CHANGE_PASSWORD,
		payload: { key, oldPassword, newPassword }
	}
}
export const wsAccountChangePasswordFulfilled = generateWebserviceFetchAction(actions.WS_ACCOUNT_CHANGE_PASSWORD_FULFILLED)
export const wsAccountChangePasswordRejected = generateWebserviceFetchAction(actions.WS_ACCOUNT_CHANGE_PASSWORD_REJECTED)
export const wsAccountChangePasswordErrorHide = generateWebserviceFetchAction(actions.WS_ACCOUNT_CHANGE_PASSWORD_ERROR_HIDE)
export const wsAccountChangePasswordMemRelease = generateWebserviceFetchAction(actions.WS_ACCOUNT_CHANGE_PASSWORD_MEM_RELEASE)
export const wsAccountChangePasswordMemRefInc = generateWebserviceFetchAction(actions.WS_ACCOUNT_CHANGE_PASSWORD_MEM_REF_INC)
export const wsAccountChangePasswordMemRefDec = generateWebserviceFetchAction(actions.WS_ACCOUNT_CHANGE_PASSWORD_MEM_REF_DEC)

export function wsCardList(key) {
	return {
		type: actions.WS_CARD_LIST,
		payload: { key }
	}
}
export const wsCardListFulfilled = generateWebserviceFetchAction(actions.WS_CARD_LIST_FULFILLED)
export const wsCardListRejected = generateWebserviceFetchAction(actions.WS_CARD_LIST_REJECTED)
export const wsCardListErrorHide = generateWebserviceFetchAction(actions.WS_CARD_LIST_ERROR_HIDE)
export const wsCardListMemRelease = generateWebserviceFetchAction(actions.WS_CARD_LIST_MEM_RELEASE)
export const wsCardListMemRefInc = generateWebserviceFetchAction(actions.WS_CARD_LIST_MEM_REF_INC)
export const wsCardListMemRefDec = generateWebserviceFetchAction(actions.WS_CARD_LIST_MEM_REF_DEC)

export function wsCardSave(key, cardData) {
	return {
		type: actions.WS_CARD_SAVE,
		payload: { key, cardData }
	}
}
export const wsCardSaveFulfilled = generateWebserviceFetchAction(actions.WS_CARD_SAVE_FULFILLED)
export const wsCardSaveRejected = generateWebserviceFetchAction(actions.WS_CARD_SAVE_REJECTED)
export const wsCardSaveErrorHide = generateWebserviceFetchAction(actions.WS_CARD_SAVE_ERROR_HIDE)
export const wsCardSaveMemRelease = generateWebserviceFetchAction(actions.WS_CARD_SAVE_MEM_RELEASE)
export const wsCardSaveMemRefInc = generateWebserviceFetchAction(actions.WS_CARD_SAVE_MEM_REF_INC)
export const wsCardSaveMemRefDec = generateWebserviceFetchAction(actions.WS_CARD_SAVE_MEM_REF_DEC)

export function wsCardDelete(key, cardId) {
	return {
		type: actions.WS_CARD_DELETE,
		payload: { key, cardId }
	}
}
export const wsCardDeleteFulfilled = generateWebserviceFetchAction(actions.WS_CARD_DELETE_FULFILLED)
export const wsCardDeleteRejected = generateWebserviceFetchAction(actions.WS_CARD_DELETE_REJECTED)
export const wsCardDeleteErrorHide = generateWebserviceFetchAction(actions.WS_CARD_DELETE_ERROR_HIDE)
export const wsCardDeleteMemRelease = generateWebserviceFetchAction(actions.WS_CARD_DELETE_MEM_RELEASE)
export const wsCardDeleteMemRefInc = generateWebserviceFetchAction(actions.WS_CARD_DELETE_MEM_REF_INC)
export const wsCardDeleteMemRefDec = generateWebserviceFetchAction(actions.WS_CARD_DELETE_MEM_REF_DEC)

export function wsCardProjectDelete(key, cardProjectId) {
	return {
		type: actions.WS_CARD_PROJECT_DELETE,
		payload: { key, cardProjectId }
	}
}
export const wsCardProjectDeleteFulfilled = generateWebserviceFetchAction(actions.WS_CARD_PROJECT_DELETE_FULFILLED)
export const wsCardProjectDeleteRejected = generateWebserviceFetchAction(actions.WS_CARD_PROJECT_DELETE_REJECTED)
export const wsCardProjectDeleteErrorHide = generateWebserviceFetchAction(actions.WS_CARD_PROJECT_DELETE_ERROR_HIDE)
export const wsCardProjectDeleteMemRelease = generateWebserviceFetchAction(actions.WS_CARD_PROJECT_DELETE_MEM_RELEASE)
export const wsCardProjectDeleteMemRefInc = generateWebserviceFetchAction(actions.WS_CARD_PROJECT_DELETE_MEM_REF_INC)
export const wsCardProjectDeleteMemRefDec = generateWebserviceFetchAction(actions.WS_CARD_PROJECT_DELETE_MEM_REF_DEC)

export function wsCardProjectAssignment(key, cardProjectId, cardIds) {
	return {
		type: actions.WS_CARD_PROJECT_ASSIGNMENT,
		payload: { key, cardProjectId, cardIds }
	}
}
export const wsCardProjectAssignmentFulfilled = generateWebserviceFetchAction(actions.WS_CARD_PROJECT_ASSIGNMENT_FULFILLED)
export const wsCardProjectAssignmentRejected = generateWebserviceFetchAction(actions.WS_CARD_PROJECT_ASSIGNMENT_REJECTED)
export const wsCardProjectAssignmentErrorHide = generateWebserviceFetchAction(actions.WS_CARD_PROJECT_ASSIGNMENT_ERROR_HIDE)
export const wsCardProjectAssignmentMemRelease = generateWebserviceFetchAction(actions.WS_CARD_PROJECT_ASSIGNMENT_MEM_RELEASE)
export const wsCardProjectAssignmentMemRefInc = generateWebserviceFetchAction(actions.WS_CARD_PROJECT_ASSIGNMENT_MEM_REF_INC)
export const wsCardProjectAssignmentMemRefDec = generateWebserviceFetchAction(actions.WS_CARD_PROJECT_ASSIGNMENT_MEM_REF_DEC)

export function wsCardProjectSave(key, cardProjectData) {
	return {
		type: actions.WS_CARD_PROJECT_SAVE,
		payload: { key, cardProjectData }
	}
}
export const wsCardProjectSaveFulfilled = generateWebserviceFetchAction(actions.WS_CARD_PROJECT_SAVE_FULFILLED)
export const wsCardProjectSaveRejected = generateWebserviceFetchAction(actions.WS_CARD_PROJECT_SAVE_REJECTED)
export const wsCardProjectSaveErrorHide = generateWebserviceFetchAction(actions.WS_CARD_PROJECT_SAVE_ERROR_HIDE)
export const wsCardProjectSaveMemRelease = generateWebserviceFetchAction(actions.WS_CARD_PROJECT_SAVE_MEM_RELEASE)
export const wsCardProjectSaveMemRefInc = generateWebserviceFetchAction(actions.WS_CARD_PROJECT_SAVE_MEM_REF_INC)
export const wsCardProjectSaveMemRefDec = generateWebserviceFetchAction(actions.WS_CARD_PROJECT_SAVE_MEM_REF_DEC)

export function wsCardProjectList(key) {
	return {
		type: actions.WS_CARD_PROJECT_LIST,
		payload: { key }
	}
}
export const wsCardProjectListFulfilled = generateWebserviceFetchAction(actions.WS_CARD_PROJECT_LIST_FULFILLED)
export const wsCardProjectListRejected = generateWebserviceFetchAction(actions.WS_CARD_PROJECT_LIST_REJECTED)
export const wsCardProjectListErrorHide = generateWebserviceFetchAction(actions.WS_CARD_PROJECT_LIST_ERROR_HIDE)
export const wsCardProjectListMemRelease = generateWebserviceFetchAction(actions.WS_CARD_PROJECT_LIST_MEM_RELEASE)
export const wsCardProjectListMemRefInc = generateWebserviceFetchAction(actions.WS_CARD_PROJECT_LIST_MEM_REF_INC)
export const wsCardProjectListMemRefDec = generateWebserviceFetchAction(actions.WS_CARD_PROJECT_LIST_MEM_REF_DEC)

export function wsCardGroupDelete(key, cardGroupId) {
	return {
		type: actions.WS_CARD_GROUP_DELETE,
		payload: { key, cardGroupId }
	}
}
export const wsCardGroupDeleteFulfilled = generateWebserviceFetchAction(actions.WS_CARD_GROUP_DELETE_FULFILLED)
export const wsCardGroupDeleteRejected = generateWebserviceFetchAction(actions.WS_CARD_GROUP_DELETE_REJECTED)
export const wsCardGroupDeleteErrorHide = generateWebserviceFetchAction(actions.WS_CARD_GROUP_DELETE_ERROR_HIDE)
export const wsCardGroupDeleteMemRelease = generateWebserviceFetchAction(actions.WS_CARD_GROUP_DELETE_MEM_RELEASE)
export const wsCardGroupDeleteMemRefInc = generateWebserviceFetchAction(actions.WS_CARD_GROUP_DELETE_MEM_REF_INC)
export const wsCardGroupDeleteMemRefDec = generateWebserviceFetchAction(actions.WS_CARD_GROUP_DELETE_MEM_REF_DEC)

export function wsCardGroupSave(key, cardGroupData) {
	return {
		type: actions.WS_CARD_GROUP_SAVE,
		payload: { key, cardGroupData }
	}
}
export const wsCardGroupSaveFulfilled = generateWebserviceFetchAction(actions.WS_CARD_GROUP_SAVE_FULFILLED)
export const wsCardGroupSaveRejected = generateWebserviceFetchAction(actions.WS_CARD_GROUP_SAVE_REJECTED)
export const wsCardGroupSaveErrorHide = generateWebserviceFetchAction(actions.WS_CARD_GROUP_SAVE_ERROR_HIDE)
export const wsCardGroupSaveMemRelease = generateWebserviceFetchAction(actions.WS_CARD_GROUP_SAVE_MEM_RELEASE)
export const wsCardGroupSaveMemRefInc = generateWebserviceFetchAction(actions.WS_CARD_GROUP_SAVE_MEM_REF_INC)
export const wsCardGroupSaveMemRefDec = generateWebserviceFetchAction(actions.WS_CARD_GROUP_SAVE_MEM_REF_DEC)

export function wsCardGroupList(key) {
	return {
		type: actions.WS_CARD_GROUP_LIST,
		payload: { key }
	}
}
export const wsCardGroupListFulfilled = generateWebserviceFetchAction(actions.WS_CARD_GROUP_LIST_FULFILLED)
export const wsCardGroupListRejected = generateWebserviceFetchAction(actions.WS_CARD_GROUP_LIST_REJECTED)
export const wsCardGroupListErrorHide = generateWebserviceFetchAction(actions.WS_CARD_GROUP_LIST_ERROR_HIDE)
export const wsCardGroupListMemRelease = generateWebserviceFetchAction(actions.WS_CARD_GROUP_LIST_MEM_RELEASE)
export const wsCardGroupListMemRefInc = generateWebserviceFetchAction(actions.WS_CARD_GROUP_LIST_MEM_REF_INC)
export const wsCardGroupListMemRefDec = generateWebserviceFetchAction(actions.WS_CARD_GROUP_LIST_MEM_REF_DEC)

export function wsScannedCardList(key) {
	return {
		type: actions.WS_SCANNED_CARD_LIST,
		payload: { key }
	}
}
export const wsScannedCardListFulfilled = generateWebserviceFetchAction(actions.WS_SCANNED_CARD_LIST_FULFILLED)
export const wsScannedCardListRejected = generateWebserviceFetchAction(actions.WS_SCANNED_CARD_LIST_REJECTED)
export const wsScannedCardListErrorHide = generateWebserviceFetchAction(actions.WS_SCANNED_CARD_LIST_ERROR_HIDE)
export const wsScannedCardListMemRelease = generateWebserviceFetchAction(actions.WS_SCANNED_CARD_LIST_MEM_RELEASE)
export const wsScannedCardListMemRefInc = generateWebserviceFetchAction(actions.WS_SCANNED_CARD_LIST_MEM_REF_INC)
export const wsScannedCardListMemRefDec = generateWebserviceFetchAction(actions.WS_SCANNED_CARD_LIST_MEM_REF_DEC)

export function wsScannedCardDelete(key, scannedCardIds) {
	return {
		type: actions.WS_SCANNED_CARD_DELETE,
		payload: { key, scannedCardIds }
	}
}
export const wsScannedCardDeleteFulfilled = generateWebserviceFetchAction(actions.WS_SCANNED_CARD_DELETE_FULFILLED)
export const wsScannedCardDeleteRejected = generateWebserviceFetchAction(actions.WS_SCANNED_CARD_DELETE_REJECTED)
export const wsScannedCardDeleteErrorHide = generateWebserviceFetchAction(actions.WS_SCANNED_CARD_DELETE_ERROR_HIDE)
export const wsScannedCardDeleteMemRelease = generateWebserviceFetchAction(actions.WS_SCANNED_CARD_DELETE_MEM_RELEASE)
export const wsScannedCardDeleteMemRefInc = generateWebserviceFetchAction(actions.WS_SCANNED_CARD_DELETE_MEM_REF_INC)
export const wsScannedCardDeleteMemRefDec = generateWebserviceFetchAction(actions.WS_SCANNED_CARD_DELETE_MEM_REF_DEC)

export function wsScannedCardGroupAssignment(key, groupId, cardIds) {
	return {
		type: actions.WS_SCANNED_CARD_GROUP_ASSIGNMENT,
		payload: { key, groupId, cardIds }
	}
}
export const wsScannedCardGroupAssignmentFulfilled = generateWebserviceFetchAction(actions.WS_SCANNED_CARD_GROUP_ASSIGNMENT_FULFILLED)
export const wsScannedCardGroupAssignmentRejected = generateWebserviceFetchAction(actions.WS_SCANNED_CARD_GROUP_ASSIGNMENT_REJECTED)
export const wsScannedCardGroupAssignmentErrorHide = generateWebserviceFetchAction(actions.WS_SCANNED_CARD_GROUP_ASSIGNMENT_ERROR_HIDE)
export const wsScannedCardGroupAssignmentMemRelease = generateWebserviceFetchAction(actions.WS_SCANNED_CARD_GROUP_ASSIGNMENT_MEM_RELEASE)
export const wsScannedCardGroupAssignmentMemRefInc = generateWebserviceFetchAction(actions.WS_SCANNED_CARD_GROUP_ASSIGNMENT_MEM_REF_INC)
export const wsScannedCardGroupAssignmentMemRefDec = generateWebserviceFetchAction(actions.WS_SCANNED_CARD_GROUP_ASSIGNMENT_MEM_REF_DEC)