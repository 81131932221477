import { useState } from "react";
import Card from "@mui/material/Card";
import { useNavigate } from "react-router-dom";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { toast } from "react-toastify";
// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-reset-cover.jpeg";
import { apiUrl } from "helpers/const";

function NewPassword() {
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const navigate = useNavigate();
  const submitHandler = (e) => {
    e.preventDefault();
    if (password !== password2) {
      toast.warning("Uwaga! Podane hasła nie są takie same!");
      return;
    }
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const token = urlParams.get("token")
    let fetchAddress = apiUrl
    fetch(fetchAddress + "account/reset_password?token="+token, {
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      method: "POST",
      body: JSON.stringify({new_password: password}),
      credentials: 'include'
    })
      .then(async response => {
        if (!response.ok) {
          const text = await response.text();
          toast.error(JSON.parse(text).error)
          return;
        }
        toast.success("Twoje nowe hasło zostało zapisane. Możesz się już zalogować.")
        setTimeout(() => {
          navigate('/authentication/sign-in')
        }, 5000)
      })
      .catch(async response => {
        const text = response.text;
        if (response.status != null) {
          switch (response.status) {
            case 500: response.errorMessage = response.statusText + " (" + response.status + ")"; break;
            default: response.errorMessage = JSON.parse(text).error; break;
          }
        }
        if (response.message != null) response.errorMessage = "Brak połączenia z serwerem"
        toast.error("Błąd! "+response.errorMessage)
        setTimeout(() => {
          navigate('/authentication/sign-in')
        }, 5000)
        return;
      })
  }

  return (
    <CoverLayout coverHeight="50vh" image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          py={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
            Ustaw nowe hasło
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Wprowadź dwukrotnie swoje nowe hasło, aby dokonać zmiany.
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={4}>
              <MDInput type="password" label="Nowe hasło" variant="standard" fullWidth value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }} />
                <MDInput type="password" label="Powtórz hasło" variant="standard" fullWidth value={password2}
                onChange={(e) => {
                  setPassword2(e.target.value);
                }} />
            </MDBox>
            <MDBox mt={6} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth onClick={submitHandler}>
                zmień
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default NewPassword;
