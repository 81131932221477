import { call, put, select, takeEvery } from 'redux-saga/effects'
import actionType from '../actions/_actionTypes.js'
import * as ws from '../api/webservice.js'
import * as actions from '../actions/webserviceActions.js'
import { takeFirstPerKey_CancelOnDifferentParams, takeFirst_CancelOnDifferentParams, takeFirst, shouldFetchData } from '../utils/saga.js'
import { toast } from 'react-toastify'

export const getServerPoolId = (state) => state.webservice.serverPoolId;
const getLoggedIn = (state) => state.webservice.loggedIn;
export const getWsPath = (state) => state.webservice.wsPath;
export const getExternalServerUrl = (state) => state.webservice.externalServerUrl;
//const getLocalizedString = (state) => state.desktopUI.localizedString

//TODO: Sprawdzać czy użytkownik jest zalogowany, i zwracać błąd gdy nie jest.
//TODO: Dodaj timeout i anulowanie wywołań.
//TODO: Dodaj debounce dla objectList i HistoryList

//TODO: Sprawdz czy gdy wywolasz logowanie ponownie z innym loginem i hasłem w trakcie logowania, czy poprzednie requesty zostaną abortowane z jednym blokiem finally?
export function* wsUserLogIn(action) {
  let wsPath = yield select(getWsPath);
  let abortableFetch;
  try {
    abortableFetch = ws.userLogIn(action.payload.email, action.payload.password)
    const data = yield call(abortableFetch.promise);
    yield put(actions.wsUserLogInFulfilled(data));
    //yield put(actions.wsAccountList({ id: "global" }))
  } catch (err) {
    if (typeof err.redirect_server_pool_id === "undefined") {
      yield put(actions.wsUserLogInRejected(err));
      //toast.error(err.errorMessage)
    } else {
      try {
        abortableFetch = ws.userLogIn(action.payload.email, action.payload.password, err.redirect_server_pool_id, wsPath)
        const data = yield call(abortableFetch.promise);
        yield put(actions.wsUserLogInFulfilled(data.data, data.serverPoolId));
        yield put(actions.wsUserLoggedIn({id: "global"}))
      } catch (err) {
        yield put(actions.wsUserLogInRejected(err));
        //toast.error(err.errorMessage)
      }
    }
  } finally {
    if (abortableFetch != null) abortableFetch.abort();
  }
}

export function* wsUserLogOut() {
  let abortableFetch;
  try {
    let loggedIn = yield select(getLoggedIn);
    //let localizedString = yield select(getLocalizedString)
    if (loggedIn === false) {
      throw new Error("logged in false")
    }
    abortableFetch = ws.userLogOut()
    const data = yield call(abortableFetch.promise);
    yield put(actions.wsUserLogOutFulfilled(data));
  } catch (err) {
    yield put(actions.wsUserLogOutRejected(err));
  } finally {
    if (abortableFetch != null) abortableFetch.abort();
  }
}

export function* wsUserRemindPassword(action) {
  const { key, username, sendto } = action.payload
  let abortableFetch;
  try {
    abortableFetch = ws.userRemindPassword(username, sendto)
    const data = yield call(abortableFetch.promise);
    yield put(actions.wsUserRemindPasswordFulfilled(key, data));
  } catch (err) {
    yield put(actions.wsUserRemindPasswordRejected(key, err));
  } finally {
    if (abortableFetch != null) abortableFetch.abort();
  }
}

export function* wsUserInfo(action) {
  const { key } = action.payload
  let abortableFetch;
  try {
    let loggedIn = yield select(getLoggedIn);
    //let localizedString = yield select(getLocalizedString)
    if (loggedIn === false) {
      throw new Error("logged in false")
    }
    abortableFetch = ws.userInfo()
    const data = yield call(abortableFetch.promise);
    yield put(actions.wsUserInfoFulfilled(key, data));
  } catch (err) {
    yield put(actions.wsUserInfoRejected(key, err));
  } finally {
    if (abortableFetch != null) abortableFetch.abort();
  }
}

export function* wsAccountList(action) {
  const { key } = action.payload
  let abortableFetch;
  try {
    let loggedIn = yield select(getLoggedIn);
    //let localizedString = yield select(getLocalizedString)
    if (loggedIn === false) {
      throw new Error("localizedString.loggedOutLogin")
    }
    abortableFetch = ws.accountList()
    const data = yield call(abortableFetch.promise);
    yield put(actions.wsAccountListFulfilled(key, data));
  } catch (err) {
    yield put(actions.wsAccountListRejected(key, err));
  } finally {
    if (abortableFetch != null) abortableFetch.abort();
  }
}

export function* wsAccountChangePassword(action) {
  const { key, oldPassword, newPassword } = action.payload
  let abortableFetch;
  try {
    let loggedIn = yield select(getLoggedIn);
    //let localizedString = yield select(getLocalizedString)
    if (loggedIn === false) {
      throw new Error("localizedString.loggedOutLogin")
    }
    abortableFetch = ws.accountChangePassword(oldPassword, newPassword)
    const data = yield call(abortableFetch.promise);
    yield put(actions.wsAccountChangePasswordFulfilled(key, data));
    toast.success("Hasło zostało zmienione")
  } catch (err) {
    yield put(actions.wsAccountChangePasswordRejected(key, err));
  } finally {
    if (abortableFetch != null) abortableFetch.abort();
  }
}

export function* wsCardList(action) {
  const { key } = action.payload
  let abortableFetch;
  try {
    let loggedIn = yield select(getLoggedIn);
    //let localizedString = yield select(getLocalizedString)
    if (loggedIn === false) {
      throw new Error("localizedString.loggedOutLogin")
    }
    abortableFetch = ws.cardList()
    const data = yield call(abortableFetch.promise);
    yield put(actions.wsCardListFulfilled(key, data));
  } catch (err) {
    yield put(actions.wsCardListRejected(key, err));
  } finally {
    if (abortableFetch != null) abortableFetch.abort();
  }
}

export function* wsCardSave(action) {
  const { key, cardData } = action.payload
  let abortableFetch;
  try {
    let loggedIn = yield select(getLoggedIn);
    //let localizedString = yield select(getLocalizedString)
    if (loggedIn === false) {
      throw new Error("localizedString.loggedOutLogin")
    }
    abortableFetch = ws.cardSave(cardData)
    const data = yield call(abortableFetch.promise);
    yield put(actions.wsCardSaveFulfilled(key, data));
  } catch (err) {
    yield put(actions.wsCardSaveRejected(key, err));
  } finally {
    if (abortableFetch != null) abortableFetch.abort();
  }
}

export function* wsCardDelete(action) {
  const { key, cardId } = action.payload
  let abortableFetch;
  try {
    let loggedIn = yield select(getLoggedIn);
    //let localizedString = yield select(getLocalizedString)
    if (loggedIn === false) {
      throw new Error("localizedString.loggedOutLogin")
    }
    abortableFetch = ws.cardDelete(cardId)
    const data = yield call(abortableFetch.promise);
    yield put(actions.wsCardDeleteFulfilled(key, data));
  } catch (err) {
    yield put(actions.wsCardDeleteRejected(key, err));
  } finally {
    if (abortableFetch != null) abortableFetch.abort();
  }
}

export function* wsCardGroupList(action) {
  const { key } = action.payload
  let abortableFetch;
  try {
    let loggedIn = yield select(getLoggedIn);
    //let localizedString = yield select(getLocalizedString)
    if (loggedIn === false) {
      throw new Error("localizedString.loggedOutLogin")
    }
    abortableFetch = ws.cardGroupList()
    const data = yield call(abortableFetch.promise);
    yield put(actions.wsCardGroupListFulfilled(key, data));
  } catch (err) {
    yield put(actions.wsCardGroupListRejected(key, err));
  } finally {
    if (abortableFetch != null) abortableFetch.abort();
  }
}

export function* wsCardGroupSave(action) {
  const { key, cardGroupData } = action.payload
  let abortableFetch;
  try {
    let loggedIn = yield select(getLoggedIn);
    //let localizedString = yield select(getLocalizedString)
    if (loggedIn === false) {
      throw new Error("localizedString.loggedOutLogin")
    }
    abortableFetch = ws.cardGroupSave(cardGroupData)
    const data = yield call(abortableFetch.promise);
    yield put(actions.wsCardGroupSaveFulfilled(key, data));
  } catch (err) {
    yield put(actions.wsCardGroupSaveRejected(key, err));
  } finally {
    if (abortableFetch != null) abortableFetch.abort();
  }
}

export function* wsCardGroupDelete(action) {
  const { key, cardGroupId } = action.payload
  let abortableFetch;
  try {
    let loggedIn = yield select(getLoggedIn);
    //let localizedString = yield select(getLocalizedString)
    if (loggedIn === false) {
      throw new Error("localizedString.loggedOutLogin")
    }
    abortableFetch = ws.cardGroupDelete(cardGroupId)
    const data = yield call(abortableFetch.promise);
    yield put(actions.wsCardGroupDeleteFulfilled(key, data));
  } catch (err) {
    yield put(actions.wsCardGroupDeleteRejected(key, err));
  } finally {
    if (abortableFetch != null) abortableFetch.abort();
  }
}

export function* wsCardProjectList(action) {
  const { key } = action.payload
  let abortableFetch;
  try {
    let loggedIn = yield select(getLoggedIn);
    //let localizedString = yield select(getLocalizedString)
    if (loggedIn === false) {
      throw new Error("localizedString.loggedOutLogin")
    }
    abortableFetch = ws.cardProjectList()
    const data = yield call(abortableFetch.promise);
    yield put(actions.wsCardProjectListFulfilled(key, data));
  } catch (err) {
    yield put(actions.wsCardProjectListRejected(key, err));
  } finally {
    if (abortableFetch != null) abortableFetch.abort();
  }
}

export function* wsCardProjectSave(action) {
  const { key, cardProjectData } = action.payload
  let abortableFetch;
  try {
    let loggedIn = yield select(getLoggedIn);
    //let localizedString = yield select(getLocalizedString)
    if (loggedIn === false) {
      throw new Error("localizedString.loggedOutLogin")
    }
    abortableFetch = ws.cardProjectSave(cardProjectData)
    const data = yield call(abortableFetch.promise);
    yield put(actions.wsCardProjectSaveFulfilled(key, data));
  } catch (err) {
    yield put(actions.wsCardProjectSaveRejected(key, err));
  } finally {
    if (abortableFetch != null) abortableFetch.abort();
  }
}

export function* wsCardProjectDelete(action) {
  const { key, cardProjectId } = action.payload
  let abortableFetch;
  try {
    let loggedIn = yield select(getLoggedIn);
    //let localizedString = yield select(getLocalizedString)
    if (loggedIn === false) {
      throw new Error("localizedString.loggedOutLogin")
    }
    abortableFetch = ws.cardProjectDelete(cardProjectId)
    const data = yield call(abortableFetch.promise);
    yield put(actions.wsCardProjectDeleteFulfilled(key, data));
  } catch (err) {
    yield put(actions.wsCardProjectDeleteRejected(key, err));
  } finally {
    if (abortableFetch != null) abortableFetch.abort();
  }
}

export function* wsCardProjectAssignment(action) {
  const { key, cardProjectId, cardIds } = action.payload
  let abortableFetch;
  try {
    let loggedIn = yield select(getLoggedIn);
    //let localizedString = yield select(getLocalizedString)
    if (loggedIn === false) {
      throw new Error("localizedString.loggedOutLogin")
    }
    abortableFetch = ws.cardProjectAssignment(cardProjectId, cardIds)
    const data = yield call(abortableFetch.promise);
    yield put(actions.wsCardProjectAssignmentFulfilled(key, data));
  } catch (err) {
    yield put(actions.wsCardProjectAssignmentRejected(key, err));
  } finally {
    if (abortableFetch != null) abortableFetch.abort();
  }
}

export function* wsScannedCardList(action) {
  const { key } = action.payload
  let abortableFetch;
  try {
    let loggedIn = yield select(getLoggedIn);
    //let localizedString = yield select(getLocalizedString)
    if (loggedIn === false) {
      throw new Error("localizedString.loggedOutLogin")
    }
    abortableFetch = ws.scannedCardList()
    const data = yield call(abortableFetch.promise);
    yield put(actions.wsScannedCardListFulfilled(key, data));
  } catch (err) {
    yield put(actions.wsScannedCardListRejected(key, err));
  } finally {
    if (abortableFetch != null) abortableFetch.abort();
  }
}

export function* wsScannedCardDelete(action) {
  const { key, cardIds } = action.payload
  let abortableFetch;
  try {
    let loggedIn = yield select(getLoggedIn);
    //let localizedString = yield select(getLocalizedString)
    if (loggedIn === false) {
      throw new Error("localizedString.loggedOutLogin")
    }
    abortableFetch = ws.scannedCardDelete(cardIds)
    const data = yield call(abortableFetch.promise);
    yield put(actions.wsScannedCardDeleteFulfilled(key, data));
  } catch (err) {
    yield put(actions.wsScannedCardDeleteRejected(key, err));
  } finally {
    if (abortableFetch != null) abortableFetch.abort();
  }
}

export function* wsScannedCardGroupAssignment(action) {
  const { key, groupId, cardIds } = action.payload
  let abortableFetch;
  try {
    let loggedIn = yield select(getLoggedIn);
    //let localizedString = yield select(getLocalizedString)
    if (loggedIn === false) {
      throw new Error("localizedString.loggedOutLogin")
    }
    abortableFetch = ws.scannedCardGroupAssignment(groupId, cardIds)
    const data = yield call(abortableFetch.promise);
    yield put(actions.wsScannedCardGroupAssignmentFulfilled(key, data));
  } catch (err) {
    yield put(actions.wsScannedCardGroupAssignmentRejected(key, err));
  } finally {
    if (abortableFetch != null) abortableFetch.abort();
  }
}

/* export function* wsRightsList(action) {
  const { key, userId } = action.payload
  let abortableFetch;
  try {
    let loggedIn = yield select(getLoggedIn);
    let wsPath = yield select(getWsPath);
    //let localizedString = yield select(getLocalizedString)
    if (loggedIn === false) {
      throw new Error("localizedString.loggedOutLogin")
    }
    let serverPoolId = yield select(getServerPoolId);
    abortableFetch = ws.rightsList(userId, serverPoolId, wsPath)
    const data = yield call(abortableFetch.promise);
    yield put(actions.wsRightsListFulfilled(key, data));
  } catch (err) {
    yield put(actions.wsRightsListRejected(key, err));
  } finally {
    if (abortableFetch != null) abortableFetch.abort();
  }
}

export function* wsRightsSave(action) {
  const { key, userId, rights, objects, areas, tourPlans } = action.payload
  let abortableFetch;
  try {
    let loggedIn = yield select(getLoggedIn);
    let wsPath = yield select(getWsPath);
    //let localizedString = yield select(getLocalizedString)
    if (loggedIn === false) {
      throw new Error("localizedString.loggedOutLogin")
    }
    let serverPoolId = yield select(getServerPoolId);
    abortableFetch = ws.rightsSave(userId, rights, objects, areas, tourPlans, serverPoolId, wsPath)
    const data = yield call(abortableFetch.promise);
    yield put(actions.wsRightsSaveFulfilled(key, data));
  } catch (err) {
    yield put(actions.wsRightsSaveRejected(key, err));
    toast.error(err.errorMessage)
  } finally {
    if (abortableFetch != null) abortableFetch.abort();
  }
} */

export default function* watchWebserviceSaga() {
  yield takeFirst_CancelOnDifferentParams(actionType.WS_USER_LOG_IN, wsUserLogIn)
  yield takeFirst_CancelOnDifferentParams(actionType.WS_USER_LOG_OUT, wsUserLogOut)
  yield takeFirst_CancelOnDifferentParams(actionType.WS_USER_INFO, wsUserInfo)
  yield takeFirst_CancelOnDifferentParams(actionType.WS_USER_REMIND_PASSWORD, wsUserRemindPassword)
  yield takeFirstPerKey_CancelOnDifferentParams(actionType.WS_ACCOUNT_LIST, wsAccountList)
  yield takeFirstPerKey_CancelOnDifferentParams(actionType.WS_ACCOUNT_CHANGE_PASSWORD, wsAccountChangePassword)
  yield takeFirstPerKey_CancelOnDifferentParams(actionType.WS_CARD_GROUP_LIST, wsCardGroupList)
  yield takeFirstPerKey_CancelOnDifferentParams(actionType.WS_CARD_GROUP_SAVE, wsCardGroupSave)
  yield takeFirstPerKey_CancelOnDifferentParams(actionType.WS_CARD_GROUP_DELETE, wsCardGroupDelete)
  yield takeFirstPerKey_CancelOnDifferentParams(actionType.WS_CARD_LIST, wsCardList)
  yield takeFirstPerKey_CancelOnDifferentParams(actionType.WS_CARD_SAVE, wsCardSave)
  yield takeFirstPerKey_CancelOnDifferentParams(actionType.WS_CARD_DELETE, wsCardDelete)
  yield takeFirstPerKey_CancelOnDifferentParams(actionType.WS_SCANNED_CARD_LIST, wsScannedCardList)
  yield takeFirstPerKey_CancelOnDifferentParams(actionType.WS_SCANNED_CARD_DELETE, wsScannedCardDelete)
  yield takeFirstPerKey_CancelOnDifferentParams(actionType.WS_SCANNED_CARD_GROUP_ASSIGNMENT, wsScannedCardGroupAssignment)
  yield takeFirstPerKey_CancelOnDifferentParams(actionType.WS_CARD_PROJECT_LIST, wsCardProjectList)
  yield takeFirstPerKey_CancelOnDifferentParams(actionType.WS_CARD_PROJECT_SAVE, wsCardProjectSave)
  yield takeFirstPerKey_CancelOnDifferentParams(actionType.WS_CARD_PROJECT_DELETE, wsCardProjectDelete)
  yield takeFirstPerKey_CancelOnDifferentParams(actionType.WS_CARD_PROJECT_ASSIGNMENT, wsCardProjectAssignment)
 /*  yield takeFirstPerKey_CancelOnDifferentParams(actionType.WS_RIGHTS_LIST, wsRightsList)
  yield takeFirstPerKey_CancelOnDifferentParams(actionType.WS_RIGHTS_SAVE, wsRightsSave) */
}