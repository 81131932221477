import { useState } from "react";
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { toast } from "react-toastify";
// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-reset-cover.jpeg";
import { apiUrl } from "helpers/const";

function Cover() {
  const [email, setEmail] = useState("");
  const submitHandler = (e) => {
    let fetchAddress = apiUrl;
    fetch(fetchAddress + "account/reset_password", {
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({email}),
      credentials: "include"
    })
      .then(async response => {
        if (!response.ok) {
          const text = await response.text();
          toast.error(JSON.parse(text).error)
          return;
        }
        toast.success("Wiadomość z linkiem do resetowania hasła została wysłana na podany adres email");
       /*  setTimeout(() => {
          navigate('/')
        }, 5000) */
      })
      .catch(response => {
        if (response.status != null) {
          switch (response.status) {
            case 500: response.errorMessage = response.statusText + " (" + response.status + ")"; break;
            default: response.errorMessage = JSON.parse(response.text).error; break;
          }
        }
        if (response.message != null) response.errorMessage = "Brak połączenia z serwerem"
        toast.error(response.errorMessage);
        return;
      })
    e.preventDefault();
  }
  return (
    <CoverLayout coverHeight="50vh" image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          py={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
            Resetowanie hasła
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Podaj e-mail, na który przyjdzie link do zresetowania hasła
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={4}>
              <MDInput type="email" label="Email" variant="standard" fullWidth value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }} />
            </MDBox>
            <MDBox mt={6} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth onClick={submitHandler}>
                resetuj
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default Cover;
